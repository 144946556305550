import React, { ReactNode, useContext, ReactElement } from 'react'
import BackgroundImage from 'gatsby-background-image'
import './ContactsBlock.scss'
import { StaticQuery, graphql } from 'gatsby'
import { ContentContext } from '../../pages'
interface Props {
  className: string
}

const ContactsBlock = (props: Props): ReactElement => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg7.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const content: any = useContext(ContentContext)
      const imageData = data.desktop.childImageSharp.fluid
      const { contacts_title, contacts_email, contacts_phone, contacts_address, contacts_conf, contacts_phone_eu, contacts_address_eu } = content
      return (
        <BackgroundImage
          critical
          Tag="section"
          className={props.className}
          fluid={imageData}
        >
          <div className="contacts_root">
            <div className="contacts_container">
              <div className="contacts_content">
                <div className="address_block">
                  <h2>{contacts_title}</h2>
                  <p>
                    <a href={`mailto:${contacts_email}`}>{contacts_email}</a>
                  </p>
                  <p>
                    <a href={`tel:${contacts_phone}`}>{contacts_phone}</a>
                  </p>
                  <p>{contacts_address}</p>
                  <p>&nbsp;</p>
                  <p>
                    <a href={`tel:${contacts_phone_eu}`}>{contacts_phone_eu}</a>
                  </p>
                  <p>{contacts_address_eu}</p>
                </div>
                <div>
                  <a className="iconLink" href={`/privacy_policy.pdf`}>
                    <svg height="14pt" viewBox="0 0 512 512" width="14pt" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill="white"
                        d="m409.785156 278.5-153.785156 153.785156-153.785156-153.785156 28.285156-28.285156 105.5 105.5v-355.714844h40v355.714844l105.5-105.5zm102.214844 193.5h-512v40h512zm0 0"
                      />
                    </svg>

                    {contacts_conf}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

export default ContactsBlock
