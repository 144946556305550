import React, { ReactNode, useContext, ReactElement } from 'react'
import BackgroundImage from 'gatsby-background-image'
import './TechBlock.scss'
import { StaticQuery, graphql } from 'gatsby'
import { ContentContext } from '../../pages'
import Img from 'gatsby-image'

interface Props {
  className: string
}

const TechBlock = (props: Props): ReactElement => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg4.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        tech: allFile(sort: { fields: relativePath }, filter: { relativePath: { regex: "/tech_/" } }) {
          edges {
            node {
              childImageSharp {
                id
                fluid(quality: 100, maxWidth: 225) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const content: any = React.useContext(ContentContext)
      const {
        tech_title,
        tech_subtitle,
        tech_first_subtitle,
        tech_first_caption,
        tech_second_subtitle,
        tech_second_caption,
        tech_third_subtitle,
        tech_third_caption,
      } = content;
      const imageData = data.desktop.childImageSharp.fluid
      const [tech1, tech2, tech3] = data.tech.edges.map((i: any) => i.node.childImageSharp)
      return (
        <BackgroundImage
          critical
          Tag="section"
          className={props.className}
          fluid={imageData}

        >
          <div className="tech_root">
            <div className="tech_container">
              <div className="tech_content">
                <h2>{tech_title}</h2>
                <p>{tech_subtitle}</p>
                <div className="tech_item_container">
                  <div className={"tech_item"}>
                    <div className={"text_item_desc"}>
                      <h3>{tech_first_subtitle}</h3>
                      <p>{tech_first_caption}</p>
                    </div>
                    <Img fluid={tech1.fluid}></Img>
                  </div>
                  <div className={"tech_item"}>
                    <div className={"text_item_desc"}>
                      <h3>{tech_second_subtitle}</h3>
                      <p>{tech_second_caption}</p>
                    </div>
                    <Img fluid={tech2.fluid}></Img>
                  </div>
                  <div className={"tech_item"}>
                    <div className={"text_item_desc"}>
                      <h3>{tech_third_subtitle}</h3>
                      <p>{tech_third_caption}</p>
                    </div>
                    <Img fluid={tech3.fluid}></Img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

export default TechBlock
