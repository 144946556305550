import React, { ReactNode, useContext, ReactElement } from 'react'
import BackgroundImage from 'gatsby-background-image'
import './ProdBlock.scss'
import { StaticQuery, graphql} from 'gatsby'
import { ContentContext } from '../../pages'
import Img from 'gatsby-image'

interface Props {
  className: string
}


const ProdBlock = (props: Props): ReactElement => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg6.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        clouds: file(relativePath: { eq: "clouds.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const content: any = React.useContext(ContentContext)
      const cloud = data.clouds.childImageSharp.fluid
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          critical
          Tag="section"
          className={props.className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          <div className="prod_root">
            <div className="clouds">
              <Img fluid={cloud}></Img>
            </div>
            <div className="prod_container">
              <div className="prod_content">
                <h2>{content.production_title}</h2>
                <p>{content.production_text}</p>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

export default ProdBlock