import React, { ReactNode, useContext, ReactElement } from 'react'
import BackgroundImage from 'gatsby-background-image'
import './ProjectsBlock.scss'
import { StaticQuery, graphql } from 'gatsby'
import { ContentContext } from '../../pages'
import Img from 'gatsby-image'
interface Props {
  className: string
}

const ProjectsBlock = (props: Props): ReactElement => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg5.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        objects: allFile(sort: { fields: relativePath }, filter: { relativePath: { regex: "/object_/" } }) {
          edges {
            node {
              childImageSharp {
                id
                fluid(quality: 100, maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const content: any = React.useContext(ContentContext)
      const imageData = data.desktop.childImageSharp.fluid
      const [obj1, obj2] = data.objects.edges.map((i: any) => i.node.childImageSharp)
      return (
        <BackgroundImage
          critical
          Tag="section"
          className={props.className}
          fluid={imageData}
        >
          <div className="projects_root">
            <div className="projects_container">
              <div className="projects_content">
                <h2>{content.concept_title}</h2>
                <p>{content.concept_text}</p>
              </div>
              <div className="objects_container">
                <div className="object">
                  <div className="object_cover">
                    <h3>{content.project1_title}</h3>
                    <p>{content.project1_area}</p>
                    <p>{content.project1_status}</p>
                  </div>
                  <Img fluid={obj1.fluid}></Img>
                </div>
                <div className="object">
                  <div className="object_cover">
                    <h3>{content.project2_title}</h3>
                    <p>{content.project2_area}</p>
                    <p>{content.project2_status}</p>
                  </div>
                  <Img fluid={obj2.fluid}></Img>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

export default ProjectsBlock
