import React, { ReactNode, useContext, ReactElement } from 'react'
import BackgroundImage from 'gatsby-background-image'
import './CabinsBlock.scss'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import 'react-responsive-modal/styles.css'
import ImageGallery from 'react-image-gallery'
import { Modal } from 'react-responsive-modal'
import { ContentContext } from '../../pages'

interface Props {
  className: string
}

const CabinsBlock = (props: Props): ReactElement => (
  <StaticQuery
    query={graphql`
      query {
        bg: file(relativePath: { eq: "bg3.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cabin1: file(relativePath: { eq: "cabin1.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 369) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        cabin2: file(relativePath: { eq: "cabin2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 369) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        gallery: allFile(sort: { fields: relativePath }, filter: { relativePath: { regex: "/img_/" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 340) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        photos: allFile(sort: { fields: relativePath }, filter: { relativePath: { regex: "/gallery/" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const content: any = React.useContext(ContentContext)
      const [tourModalIsOpen, setTourIsOpen] = React.useState(false)
      const [galleryModalIsOpen, setGalleryIsOpen] = React.useState(false)
      const [timelapseModalIsOpen, setTimelapseIsOpen] = React.useState(false)
      const bgData = data.bg.childImageSharp.fluid
      const cabin1 = data.cabin1.childImageSharp.fluid
      const cabin2 = data.cabin2.childImageSharp.fluid
      const [assembly, gallery, tour] = data.gallery.edges.map((i: any) => i.node.childImageSharp)
      const galleryImages = data.photos.edges.map((i: any) => ({ ...i.node.childImageSharp.fluid, original: '' }))
      return (
        <>
          <BackgroundImage
            critical
            fadeIn={false}
            Tag="section"
            className={props.className}
            fluid={bgData}
            backgroundColor={"black"}
          >
            <div className="cabins_root">
              <div className="cabins_container">
                <div>
                  <h2>{content.cabin_title}</h2>
                </div>
                <div className="cabins_img">
                  <div className="cabin ">
                    <Img fluid={cabin1}></Img>
                    <div className="feature_base leftCabinFeatures feature1">{content.feature10}</div>
                    <div className="feature_base leftCabinFeatures feature2">{content.feature7}</div>
                    <div className="feature_base leftCabinFeatures feature3">{content.feature2}</div>
                    <div className="feature_base leftCabinFeatures feature4">{content.feature4}</div>
                    <div className="feature_base leftCabinFeatures feature5">{content.feature9}</div>
                    <div className="feature_base leftCabinFeatures feature6">{content.feature1}</div>
                  </div>
                  <div className="cabin cabin2">
                    <Img fluid={cabin2}></Img>
                    <div className="feature_base rightCabinFeatures feature7">{content.feature1}</div>
                    <div className="feature_base rightCabinFeatures feature8">{content.feature3}</div>
                    <div className="feature_base rightCabinFeatures feature9">{content.feature5}</div>
                    <div className="feature_base rightCabinFeatures feature10">{content.feature6}</div>
                    <div className="feature_base rightCabinFeatures feature11">{content.feature2}</div>
                    <div className="feature_base rightCabinFeatures feature12">{content.feature4}</div>
                    <div className="feature_base rightCabinFeatures feature13">{content.feature8}</div>
                  </div>
                </div>
                <a className="iconLink" href="/aprilix_cabins.pdf">
                  <svg height="14pt" viewBox="0 0 512 512" width="14pt" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill="white"
                      d="m409.785156 278.5-153.785156 153.785156-153.785156-153.785156 28.285156-28.285156 105.5 105.5v-355.714844h40v355.714844l105.5-105.5zm102.214844 193.5h-512v40h512zm0 0"
                    />
                  </svg>
                  {content.cabin_link}
                </a>
              </div>
              <div className="gallery">
                <div onClick={() => setTimelapseIsOpen(true)}>
                  <p>{content.cabin_timelapse}</p>
                  <Img fluid={assembly.fluid}></Img>
                </div>
                <div onClick={() => setGalleryIsOpen(true)}>
                  <p>{content.cabin_gallery}</p>
                  <Img fluid={gallery.fluid}></Img>
                </div>
                <div onClick={() => setTourIsOpen(true)}>
                  <p>{content.cabin_pano}</p>
                  <Img fluid={tour.fluid}></Img>
                </div>
              </div>
            </div>
          </BackgroundImage>
          <Modal
            classNames={{
              modal: 'customModal',
              closeIcon: 'closeIcon',
            }}
            center={true}
            open={tourModalIsOpen}
            onClose={() => setTourIsOpen(false)}
          >
            <iframe
              width="100%"
              height="100%"
              frameBorder="0"
              allow="vr,gyroscope,accelerometer,fullscreen"
              scrolling="no"
              src="https://kuula.co/share/7JZhM?fs=1&vr=0&sd=1&thumbs=1&chromeless=0&logo=0&logosize=80"
            ></iframe>
          </Modal>
          <Modal
            classNames={{
              modal: 'customModal',
              closeIcon: 'closeIcon',
            }}
            center={true}
            open={timelapseModalIsOpen}
            onClose={() => setTimelapseIsOpen(false)}
          >
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube-nocookie.com/embed/0Dqch4jr6zc?controls=1&autoplay=1"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </Modal>
          <Modal
            classNames={{
              modal: 'customModal',
              closeIcon: 'closeIcon',
            }}
            center={true}
            open={galleryModalIsOpen}
            onClose={() => setGalleryIsOpen(false)}
          >
            <ImageGallery
              lazyLoad
              additionalClass="imgGallery"
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              items={galleryImages}
            />
          </Modal>
        </>
      )
    }}
  />
)

export default CabinsBlock
