import React, { ReactNode, useContext, ReactElement } from 'react'
import BackgroundImage from 'gatsby-background-image'
import './IdeaBlock.scss'
import { StaticQuery, graphql } from 'gatsby'
import { ContentContext } from '../../pages'

interface Props {
  className: string
}

const IdeaBlock = (props: Props): ReactElement => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg1.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const content: any = React.useContext(ContentContext);
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          critical
          fadeIn={false}
          Tag="section"
          backgroundColor={"black"}
          className={props.className}
          fluid={imageData}
        >
          <div className="idea_root">
            <div className="idea_container">
              <div className="idea_content">
                <h2>{content.idea_title}</h2>
                <p>{content.idea_text}</p>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)


export default IdeaBlock
