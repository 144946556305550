/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

interface Props {
  alt?: string
  title?: string
  maxWidth?: number
  className?: string
}

const Logo = ({ maxWidth = 220, className = '', alt, title }: Props) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fixed(quality: 100, height: 56) {
                originalName,
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        (edge: { node: { fixed: { originalName: string } } }) => edge.node.fixed.originalName === 'logo.png',
      )
      if (!image) {
        return null
      }
      return (
        <div style={{ height: "48px", maxWidth: maxWidth }}>
          <Img
            loading="eager"
            imgStyle={{
              objectFit: 'contain',
              objectPosition: '50% 50%',
            }}
            alt={alt}
            title={title}
            fadeIn={false}
            className={className}
            sizes={{
              aspectRatio: 1,
              sizes: "",
              ...image.node.fixed}}
          />
        </div>
      )
    }}
  />
)
export default Logo
