import { StaticQuery, graphql } from "gatsby"
import React from "react"

const SocialIcon = (props: { name: string; link: string }): JSX.Element => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { ext: { eq: ".svg" } }) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
      }
    `}
    render={data => {
      const [
        {
          node: { publicURL },
        },
      ] = data.allFile.edges.filter(({ node }: any) => node.name === props.name)
      return (
        <a href={props.link} target="_blank">
          <img src={publicURL} />
        </a>
      )
    }}
  />
)

export default SocialIcon
