import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts/main/Layout'
import Toolbar from '../components/Toolbar/Toolbar'
import './index.scss'
import IdeaBlock from '../components/IdeaBlock/IdeaBlock'
import ProjectsBlock from '../components/ProjectsBlock/ProjectsBlock'
import ServicesBlock from '../components/ServicesBlock/ServicesBlock'
import CabinsBlock from '../components/CabinsBlock/CabinsBlock'
import TechBlock from '../components/TechBlock/TechBlock'
import ProdBlock from '../components/ProdBlock/ProdBlock'
import ContactsBlock from '../components/ContactsBlock/ContactsBlock'
import Helmet from 'react-helmet'
interface HomepageData {
  frontmatter: {
    seo_title: string
    seo_desc: string
    text: string
    nav_menu: string[]
  }
}

interface IndexProps {
  pageContext: {
    [locale: string]: string
  }
  data: {
    services: any
    homePageData: HomepageData
  }
}

export const ContentContext = React.createContext({})

const IndexPage = ({ pageContext: { locale }, ...props }: IndexProps): ReactElement => {
  const { homePageData: data, services } = props.data

  return (
    <Layout>
      <Helmet>
        <title>{data.frontmatter.seo_title}</title>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,600;1,600&family=Open+Sans&display=swap" rel="stylesheet"></link>
        <link rel="icon" href="/favicon.ico" type="image/x-icon"></link>
        <meta name="description" content={data.frontmatter.seo_desc} />
      </Helmet>
      <ContentContext.Provider value={{ ...data.frontmatter, services }}>
        <Toolbar locale={locale} />
        <div id="idea">
          <IdeaBlock className={'block idea_block'} />
        </div>
        <div id="services">
          <ServicesBlock className={'block services_block'} />
        </div>
        <div className="anchor" id="cabins" />
          <CabinsBlock className={'cabins-block'} />
        <div className="anchor" id="tech" />
          <TechBlock className={'tech_block'} />
        <div id="projects">
          <ProjectsBlock className={'block projects_block'} />
        </div>
        <div id="production">
          <ProdBlock className={'prod_block'} />
        </div>
        <div id="contacts">
          <ContactsBlock className={'block contacts_block'} />
        </div>
      </ContentContext.Provider>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomeContent($locale: String) {
    homePageData: markdownRemark(frontmatter: { locale: { eq: $locale } }, fields: { slug: { regex: "/home/" } }) {
      frontmatter {
        locale
        seo_title
        seo_desc
        nav_menu
        idea_title
        idea_text
        cabin_title
        cabin_text
        feature1
        feature2
        feature3
        feature4
        feature5
        feature6
        feature7
        feature8
        feature9
        feature10
        cabin_link
        concept_title
        concept_text
        project1_title
        project1_area
        project1_status
        project2_title
        project2_area
        project2_status
        production_title
        production_text
        tech_title
        tech_subtitle
        tech_first_subtitle
        tech_first_caption
        tech_second_subtitle
        tech_second_caption
        tech_third_subtitle
        tech_third_caption
        project1_title
        project1_area
        project1_status
        project2_title
        project2_area
        project2_status
        contacts_title
        contacts_email
        contacts_phone
        contacts_address
        contacts_conf
        cabin_timelapse
        cabin_gallery
        cabin_pano
        contacts_phone_eu
        contacts_address_eu
      }
    }
    services: markdownRemark(frontmatter: { locale: { eq: $locale } }, fields: { slug: { regex: "/services/" } }) {
      excerpt(format: HTML, pruneLength: 10000)
    }
  }
`
