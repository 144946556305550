import React, { ReactNode, useContext, useEffect } from 'react'
import './toolbar.scss'
import { Link } from 'gatsby'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Logo from '../Logo/Logo'
import SocialIcon from './SocialIcon'
import { ContentContext } from '../../../src/pages'
interface Props {
  locale: string
}
const menuLinks = ['/#idea', '/#services', '/#cabins', '/#projects', '/#tech', '/#contacts']

const Toolbar = ({ locale }: Props): JSX.Element => {
  const content: any = React.useContext(ContentContext)
  const menuRef = React.useRef(null)
  const isPartiallyActive = (props: any) => {
    if (!props.location.hash) {
      return {}
    }
    return props.href.includes(props.location.hash) ? { className: 'activeMenu' } : {}
  }
  const toggleMenu = () => {
    menuRef.current.classList.toggle('is_open_menu')
  }
  return (
    <div style={{ position: 'relative' }}>
      <div className="root">
        <div className="container">
          <div className="logo-container">
            <Logo className="logo" />
          </div>
          <nav>
            {content.nav_menu.map((item: string, i: number) => (
              <Link
                getProps={isPartiallyActive}
                activeStyle={{ color: 'red' }}
                key={i}
                onClick={() => menuRef.current.classList.remove('is_open_menu')}
                partiallyActive={true}
                to={`${locale === 'en' ? '/en/' : ''}${menuLinks[i]}`}
              >
                {item}
              </Link>
            ))}
          </nav>
          <div className="locale">
            <span>
              {locale === 'en' ? (
                <Link to={'/'}>
                  <span>Ru</span>
                </Link>
              ) : (
                <span>Ru</span>
              )}
            </span>
            <span>
              {locale === 'ru' ? (
                <Link to={'/en'}>
                  <span>En</span>
                </Link>
              ) : (
                <span>En</span>
              )}
            </span>
          </div>
          <div className="social">
            <SocialIcon link="https://facebook.com/aprilix.official" name={'facebook'} />
            <SocialIcon link="https://www.linkedin.com/company/april-ix/" name={'linkedin'} />
            <SocialIcon link="https://instagram.com/aprilix.official" name={'instagram'} />
            {/* <img src={''} /> */}
          </div>
          <div className="burger_menu" onClick={toggleMenu}>
          <svg viewBox="0 0 100 80" width="26" height="26">
            <rect fill="#4ec3de" width="100" height="18"></rect>
            <rect fill="#4ec3de" y="30" width="100" height="18"></rect>
            <rect fill="#4ec3de" y="60" width="100" height="18"></rect>
          </svg>
          </div>
        </div>
        <nav className="responsive_menu" ref={menuRef}>
          <div className="social">
            <SocialIcon link="https://facebook.com/aprilix.official" name={'facebook'} />
            <SocialIcon link="https://www.linkedin.com/company/april-ix/" name={'linkedin'} />
            <SocialIcon link="https://instagram.com/aprilix.official" name={'instagram'} />
          </div>
          {content.nav_menu.map((item: string, i: number) => (
            <Link
              getProps={isPartiallyActive}
              activeStyle={{ color: 'red' }}
              key={i}
              partiallyActive={true}
              to={`${locale === 'en' ? '/en/' : ''}${menuLinks[i]}`}
            >
              {item}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  )
}

export default Toolbar
