import React, { ReactNode, useContext, ReactElement } from 'react'
import BackgroundImage from 'gatsby-background-image'
import './ServicesBlock.scss'
import { StaticQuery, graphql} from 'gatsby'
import { ContentContext } from '../../pages'

interface Props {
  className: string
}


const ServicesBlock = (props: Props): ReactElement => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg2.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2560) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const content: any = React.useContext(ContentContext);
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          critical
          Tag="section"
          className={props.className}
          fluid={imageData}
          backgroundColor={"black"}
        >
           <div className="services_root">
            <div className="services_container">
              <div className="services_content" dangerouslySetInnerHTML={{
                  __html: content.services.excerpt
                }}/>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

export default ServicesBlock